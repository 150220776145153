

import { BrowserRouter, Routes,Route,  } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home'
import Who_we_are from './components/Who_we_are/Who_we_are';
import How_we_support  from './components/How_we_support';
import Our_believe  from './components/Our_believe';
import Creed from './components/Creed';
import I_am_statement from './components/I_am_statement';
import Affirmation from './components/Affirmation';

import '../src/mainstyles.css'
import Programs from './components/Programs';
import Events from './components/Events';
import Emerald from './components/Emerald';
import Emerging_tech from './components/Emerging_tech';
import Contact_us from './components/Contact_us';

function App() {
  return (


   <BrowserRouter>
    <Routes>

      
    <Route  path="/" element={<Home />} />
    <Route  path="/who_we_are" element={<Who_we_are />} />
    
    <Route  path="/how_we_support" element={<How_we_support />} />

    
    <Route  path="/our_believe" element={<Our_believe />} />
    
    <Route  path="/creed" element={<Creed />} />

    
    <Route  path="/I_am_statement" element={<I_am_statement />} />

    
    <Route  path="/affirmation" element={<Affirmation />} />

    
    <Route  path="/programs" element={<Programs />} />
    
    <Route  path="/events" element={<Events />} />
    <Route  path="/emerald" element={<Emerald />} />
    
    <Route  path="/ermerging-tech" element={<Emerging_tech />} />
    
    <Route  path="/contact_us" element={<Contact_us />} />

    

    

    


    


    

    
    




      </Routes>
      </BrowserRouter>



  );
}

export default App;
