import React from 'react'
import Layout from '../../Layout/Layout'
import First_pane from './Blocks/First_pane'

const Who_we_are = () => {
  return (
    <div>
        <Layout>

  <First_pane />

        </Layout>
    </div>
  )
}

export default Who_we_are