import react from 'react'
import Layout from '../../Layout/Layout'
import First_Pane from './Blocks/First_Pane'
import Wave_pane from './Blocks/Wave_pane'
import Second_pane from './Blocks/Second_pane'
import Third_pane from './Blocks/Third_pane'
import Fourth_pane from './Blocks/Fourth_pane'
import Fifth_pane from './Blocks/Fifth_pane'




const Home = () => {
    return (
   <Layout>
   <Wave_pane />
    <First_Pane />
    <Second_pane />
    <Fourth_pane />
    <Third_pane />
    <Fifth_pane />     
    

   </Layout>
    
    )
  }
  
  export default Home