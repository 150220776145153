import React from 'react'
import Layout from '../Layout/Layout'
import Fourth_pane from './Home/Blocks/Fourth_pane'

 const How_we_support = () => {
  return (
    
       <Layout>
           <div className='uk-container uk-margin-xlarge-top'>
            <Fourth_pane />

           </div>
       </Layout>
  )
}

export default How_we_support