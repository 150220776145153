import React from 'react'

const First_Pane = () => {
  return (
    <div style={{ }}>
        <div className='uk-container'>

            <div className='row'>
                <div className='col-sm-2'>
                 
                </div>

                <div className='col-sm-8'>
                   <div className='uk-text-center uk-margin-medium-top first_pane_div'>
                       <h4 className='light'>WHO WE ARE </h4>
                       <p className='light'>The Moolu Venture Movement is a dynamic ecosystem 
                        that brings together a diverse community of entrepreneurs, 
                        mentors, investors, and experts who are passionate about
                         creating positive change.  </p>
                   </div>
                </div>

                <div className='col-sm-2'>
                
                </div>
            </div>

        </div>
    </div>
  )
}

export default First_Pane