import React from 'react'
import Layout from '../Layout/Layout'

const Affirmation = () => {
  return (
    <Layout>  
     <div className='uk-margin-xlarge-top'>
        <div className='uk-container'>
           <h3 className='light uk-text-bold'>Today, we celebrate the power of entrepreneurship and the limitless potential
               it holds. We are the voice of affirmation, resonating with confidence, passion,
               and unwavering belief in the incredible ventures that are emerging from our 
               accelerator movement.
         </h3>


         </div>

         <div className='blurb_list uk-container'>
            
         <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'>We affirm that every idea, no matter how big or small, has 
            the ability to change the world. We embrace the boldness of visionaries who dare 
            to challenge the status quo, knowing that innovation is the driving force behind progress.
              </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>



             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'>We affirm that failure is not a setback but a stepping stone
             towards growth and resilience. We encourage our entrepreneurs to rise from adversity,
              to learn from mistakes, and to emerge stronger, armed with the wisdom gained through their journey.
              </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>




             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'>We affirm that collaboration is the catalyst for 
                                  transformation. We foster a community of support,
                                  where diverse talents converge and brilliant minds
                                  unite. Together, we create a dynamic ecosystem that
                                   nurtures and empowers each entrepreneur, unlocking 
                                     their full potential.
              </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>


             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'>We affirm that dreams are meant to be realized. We inspire 
                                  and guide our founders as they navigate uncharted territories,
                                  providing them with the tools, resources, and mentorship they 
                                  need to thrive. We believe in their ability to overcome challenges
                                  and achieve greatness.
              </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>




             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'>We affirm that success is not only measured by financial gains but by
             the positive impact we have on society. We celebrate ventures that are driven by purpose, 
             aiming to solve pressing global challenges and make the world a better place.
              </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>



             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'>We affirm that the Moolu  venture movement is a global force, 
            transcending borders and cultures. We bridge gaps, connect minds, and break down barriers, 
            recognizing that true innovation knows no boundaries..
              </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>



             
             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'>
            Today and every day, we affirm the incredible potential within each entrepreneur,
             the transformative power of collaboration, and the indomitable spirit of the 
             venture accelerator movement. Together, we are building a future where dreams 
             become reality, where ideas become action, and where the world is forever changed.
              </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>



             














         </div>




    </div>


    </Layout>
 
  )
}

export default Affirmation