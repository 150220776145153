import React from 'react'
import Layout from '../Layout/Layout'
import emerald from '../images/emerald.png'

const Emerald = () => {
  return (
    <Layout>
    <div className='uk-margin-xlarge-top'>
            <div className='uk-container'>
               <div >
               <img src= {emerald} />

               <h4 className='light'>Moolu Venture Lab is thrilled to present "Emerald PitchFest 2023 Challenge,"
                 a prestigious pitch event designed to celebrate and propel the next generation
                  of growth startups in Nigeria. This exciting event aims to bring together post
                   revenue founders, seasoned investors, industry experts, and influential thought
                    leaders in a collaborative ecosystem that fosters innovation, drives economic growth,
                     and transforms Nigeria's startup landscape.</h4>
               </div>


                 <div class="uk-margin-large-top">
                     <h1 className='light uk-text-bold'>Event Overview:</h1>
                     <h4 className='light'>Emerald Challenge will be combined with Emerging Tech Summit in a one-day immersive 
                        experience that offers a unique platform for growth-focused startups to showcase their
                         disruptive ideas, gain exposure to potential investors, receive invaluable feedback
                          from industry experts, and secure potential partnerships and funding opportunities. 
                          The event will feature a series of dynamic activities, including keynote speeches,
                           panel discussions, startup pitches, networking sessions, and a grand finale award ceremony.</h4>
                 </div>


                 <div className='uk-margin-large-top'>
                    <h1 className='light uk-text-bold'>Key Highlights:</h1>

                    <div>
                   <h4 className='light'><span className='uk-text-bold'> Startups Showcase: </span>Selected growth startups across various sectors, including technology, 
                    healthcare, agriculture, fintech, and more, will present their ground-breaking ideas, innovative products,
                     and scalable business models on the main stage. Each startup will have a limited time to impress the
                      audience and panel of judges with their pitch. </h4>
                    </div>

                    
                    <div>
                   <h4 className='light'><span className='uk-text-bold'> Expert Insights: </span>Esteemed speakers and industry leaders will share their knowledge, 
                   experiences, and insights during keynote speeches and panel discussions. They will delve into critical topics such as fundraising
                    strategies, market trends, scaling businesses, overcoming challenges, and leveraging technology for growth </h4>
                    </div>


                    
                    <div>
                   <h4 className='light'><span className='uk-text-bold'> Investor Meetups:  </span>Emerald Challenge will facilitate meaningful connections between startups 
                   and potential investors through curated networking sessions and one-on-one meetings. Investors will have the opportunity to identify
                    promising ventures, explore investment opportunities, and engage in in-depth discussions with founders. </h4>
                    </div>


                    
                    <div>
                   <h4 className='light'><span className='uk-text-bold'> Mentorship and Workshops </span>Finalist will benefit from exclusive mentorship sessions and workshops via an 
                   accelerated program conducted by seasoned professionals, covering essential aspects of startup growth, such as product development,
                    marketing, sales strategies, team building, and financial planning.</h4>
                    </div>
                 </div>



            </div>
    </div>

    </Layout>
  )
}

export default Emerald