
import React ,{ useRef } from  'react'

import emailjs from 'emailjs-com';
import contact from '../images/contact.jpg'
import './contact.css'
import Layout from '../Layout/Layout';



const Contact_us = () => {
 
    const form = useRef()


    const sendEmail = (e) => {
       e.preventDefault();
   
       emailjs.sendForm('service_wvfzr3c', 'template_mz4wt6o', form.current, '-xZMMFuQXpAOcZHuZ')
         .then((result) => {
             console.log(result.text);
         }, (error) => {
             console.log(error.text);
         });
     };





  return (
    <Layout>
    <div>   
         <div className=''
    
    style={{ 
    
        backgroundImage: `-webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${contact})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingBottom: '200px',
        backgroundAttachment:'fixed',
        height: 'auto',

    
    }}
    >

          <div className='uk-container '>
            <div className=''>       
     <h1 className='light  dreamText uk-text-left uk-margin-xlarge-top' 
  >Your message, one email away.</h1>
          </div>
          </div>


          </div>






          <div className='uk-container uk-margin-large-top'>

                        <div className='uk-grid uk-grid-collapse' data-uk-grid>
                            <div className='uk-width-1-3@s uk-padding' style={{backgroundColor:'#1d4344'}}>
                               <h3 className='light'>Lets get to chat</h3>


                               <div className='uk-flex uk-padding-small  '>
                                <div><a href="" className='light' ratio='1.4' uk-icon="icon: home"></a> </div>
                                <div className='uk-margin-left'> <p className='light  contText uk-text-left'><span className='uk-text-bold'>Address: </span> 
29, Adebayo Adedeji Street Utako, Abuja.
</p> </div>
                               </div>


                               <div className='uk-flex uk-margin-top uk-padding-small '>
                                <div><a href="" className='light' ratio='1.4' uk-icon="icon: phone"></a> </div>
                                <div className='uk-margin-left'> <p className='light contText  uk-text-left'><span className='uk-text-bold'>Phone: </span> +2349168824133</p> </div>
                               </div>



                               <div className='uk-flex  uk-margin-top uk-padding-small '>
                                <div><a href="" className='light' ratio='1.4' uk-icon="icon: mail"></a> </div>
                                <div className='uk-margin-left'> <p className='light  contText uk-text-left'><span className='uk-text-bold'>Email: </span> hello@moolu.org</p> </div>
                               </div>



                            </div>

                            <div className='uk-width-2-3@s shadow' style={{backgroundColor:'#fff'}}>

                                <div className='uk-padding'>
                                                                        
                        

                                <form ref={form}  onSubmit={sendEmail}>
                                    <div className='input-row'>
                                    <div className='input-group'>
                                        <label>Name</label>
                                        <input type="text" name = "user_name"  placeholder='John Domelo' />
                                    </div>

                                    <div className='input-group'>
                                        <label>Phone</label>
                                        <input type="text" name = "phone_number" placeholder='+234 879 894' />
                                    </div>
                                    </div>


                                    <div className='input-row'>
                                    <div className='input-group'>
                                        <label>Email</label>
                                        <input type="email" name = "user_email" placeholder='ab_i@mail.com' />
                                    </div>

                                    <div className='input-group'>
                                        <label>Subject</label>
                                        <input type="text" name = "subject" placeholder='+234 879 894' />
                                    </div>
                                    </div>


                                    <label>Message</label>
                                    <textarea rows="5" name = "text-area" placeholder='Your message'></textarea>
                                    <button type='submit'>SEND</button>
                                    </form>



                                </div>

                            </div>
                        </div>

</div>


    </div>

    </Layout>
 
  )

 
}

export default Contact_us