import React from 'react'
import Layout from '../Layout/Layout'
import emerg_tech from '../images/etech.png'

const Emerging_tech = () => {
  return (
    <Layout> 
    
    <div className='uk-margin-xlarge-top'>

        <div className='uk-container'>
             <img src= {emerg_tech} />

             <h4 className='light'>Moolu Venture Capital LP proudly presents the E-Tech Summit,
                 an immersive event designed to ignite innovation, foster
                collaboration, and empower the future of technology. This
                groundbreaking summit brings together visionary entrepreneurs, 
                technology enthusiasts, industry experts, and forward-thinking 
                investors in a vibrant ecosystem aimed at shaping the landscape
                 of emerging technologies and driving transformative change.</h4>
        </div>

        <div className='uk-container uk-margin-large-top'>
           <h1 className='light uk-text-bold'>Event Overview</h1>
           <h4 className='light'>The E-Tech Summit is a two-day conference that explores the latest trends,
             breakthroughs, and opportunities in the world of technology. This dynamic event 
             offers a platform for thought-provoking discussions, captivating keynotes,
            interactive workshops, cutting-edge product showcases, and unparalleled
            networking opportunities. Attendees will gain valuable insights, forge 
            strategic partnerships, and gain a competitive edge in the rapidly 
            evolving tech industry.</h4>


            <h4 className='light uk-margin-large-top'>
            <span className='uk-text-bold'>Networking Opportunities:</span> The E-Tech Summit will provide ample networking 
            opportunities for attendees to connect with like-minded professionals, 
            industry leaders, potential collaborators, and mentors. These interactions
            will foster meaningful relationships and create a supportive community
            for future collaborations.
            </h4>

        </div>


        <div className='uk-container uk-margin-large-top'>
          <h1 className='uk-text-bold light'>Why Attend?</h1>
          <h4 className='light'>The E-Tech Summit is a must-attend event for tech enthusiasts, entrepreneurs, investors, and industry
             professionals who want to stay ahead of the curve and leverage the potential of emerging technologies.
              By participating in this summit, attendees will gain insights into the latest trends, build valuable
               connections, discover investment opportunities, and access the knowledge and resources needed to thrive
                in the technology-driven future.

Join us at the E-Tech Summit and be part of an immersive experience that empowers individuals and organizations to shape the future of technology, drive innovation, and create a positive impact on society.
</h4>
        </div>

    </div>


    </Layout>

  )
}

export default Emerging_tech