import {Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import mainLogo from '../../images/logo.png'



import { Grid, Paper, Hidden, Divider, Drawer, ListItem, List, ListItemText, IconButton } from "@material-ui/core";


function HeaderBar(props){
    const [trasparent, setTrasparent] = useState(true);
  const [numberOfItems, setNumberOfItems] = useState("")
  
  window.addEventListener("scroll", (e) => {
    setTrasparent(window.scrollY === 0);
  });

  
  
  const [openDrawer, setOpendrawer] = useState(false);


return(
    <div className="uk-container">
          
        {/** DESKTOP NAV **/}
      <div className="uk-visible@l">
             <div
      component={Grid}
      container
      className="headerBar"
      elevation={1}
      square
      style={{
        background:
          !props.fillHeader && trasparent ? "transparent" : "rgba(0,0,0,0.93)",
      }}
    >
           <div className="uk-container">
             <div className="mainNavBar">
             

             <nav class="uk-navbar-container" data-uk-navbar>

             <div class="uk-navbar-left" uk-scrollspy="cls: uk-animation-slide-left; repeat: true">
                      <Link to ="/"><img src= {mainLogo} className="logoStyle"/> </Link>
                  </div>


              
                  <div class="uk-navbar-center">
                 <nav class="stroke  "   >
                        <ul class="uk-navbar-nav ">
                            

                            <li>
                              <Link to = "/who_we_are"> Who We Are </Link> 
                             
                            </li>



                            <li><Link to= "/how_we_support">  How We Support</Link></li>



                             <li>
                                <Link to="/our_believe"> Our Believe</Link>
                                <div class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav dropDown">
                                      
                                    <Link to ="/creed">  <li class="uk-active "><a href="#">Our Creed</a></li> </Link>
                                      <Link to ="/i_am_statement">  <li class="uk-active "><a href="#">Our “I am” Statement</a></li> </Link>
                                       <Link to ="/affirmation">  <li class="uk-active "><a href="#">Our Affirmation</a></li> </Link>

                                    </ul>
                                </div>
                            </li>


                             
                            <li>
                                <Link to ="/events">Events</Link>
                                <div class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav dropDown">
                                      
                                    <Link to ="/emerald">  <li class="uk-active "><a href="#">Emerald Challenge</a></li> </Link>
                                      <Link to ="/ermerging-tech">  <li class="uk-active "><a href="#">Emerging Tech Summit</a></li> </Link>
                                   
                                    </ul>
                                </div>
                            </li>


                            <li>
                                <Link to ="/programs">Programs</Link>
                            
                            </li>


                            <li>
                                <Link to="http://blog.moolu.org/">Blog</Link>
                            
                            </li>

                            <li>
                                <Link to ="/contact_us">Contact Us</Link>
                            
                            </li>
                        </ul>
                        </nav>
                 </div> 


              <div class="uk-navbar-right ">
               
                 </div>
            </nav>






          </div>
        </div>

    </div>
    </div> {/** desktop nav ends here **/}




    
      
      {/*MOBILE NAV STARTS HERE*/}
      <div className="uk-hidden@l">
      <div
                  component={Grid}
                  container
                  className="headerBar"
                  elevation={1}
                  square
                  style={{
                    background:
                      !props.fillHeader && trasparent ? "transparent" : "rgba(0,0,0,0.93)",
                  }}
                >
                   
                        <div className="mobileNav">
                        <div className="" data-uk-grid>
                              <div className="uk-width-1-2">
                                    <div>
                                    <Link to ="/"><img src= {mainLogo} className="logoStyle"/> </Link>
                                    </div>
                              </div>

                              <div className="uk-width-1-2 ">
                                <div className="modalBtn">
                              <a class="uk-button uk-button-default  customBtn" data-uk-toggle="target: #offcanvas-flip" uk-icon="menu" uk-toggle></a>

                      <div id="offcanvas-flip" data-uk-offcanvas="flip: true; overlay: true">
                              <div class=" mobileMenuBar uk-offcanvas-bar">

                              <button class="uk-offcanvas-close" type="button" data-uk-close></button>

                             
                             

    <nav class="vertical">
      <ul>
       
      <li><Link to = "/who_we_are"> Who We Are </Link>  </li>
        
      <li><Link to= "/how_we_support">  How We Support</Link></li>
        <li><Link to="/our_believe">Our Believe +</Link>
          <ul>
            <li><Link to ="/creed">Our Creed</Link></li>
            <li><a href="#">Our “I am” Statement</a></li>
            <li><a href="#">Our Affirmation</a></li>
          </ul>
        </li>

        <li><Link to ="/events">Events +</Link>
          <ul>
            
          <Link to ="/emerald">  <li class="uk-active "><a href="#">Emerald Challenge</a></li> </Link>
          <Link to ="/ermerging-tech">  <li class="uk-active "><a href="#">Emerging Tech Summit</a></li> </Link>
          </ul>
        </li>

        <li><Link to ="/programs">Programs</Link></li>
        <li> <Link to="http://blog.moolu.org/">Blog</Link></li>
        <li> <Link to ="/contact_us">Contact Us</Link></li>
      </ul>
    </nav>





                       
                                                          










                             </div>
                       </div>
                              </div>
                              
                              
                              
                              
                              </div>
                        </div>
                        </div>
      
      
      
      
       </div>
      </div>
   














    </div>
)


}

export default HeaderBar;