import React from 'react'

const Fourth_pane = () => {
  return (
    <div className='uk-container uk-margin-large-top'>
          <div className=''>    
          <h1 className='light'>How We Help Startups</h1>
             <div className='uk-grid' data-uk-grid>
              <div className='uk-width-1-4@s'>
                  <div>
                  <h3 className='light uk-text-bold'>Funding</h3> 
                  <p className='light'>We provide early-stage and growth capital to promising startups that align with our mission. </p> 
                    </div>
              </div>

              <div className='uk-width-1-4@s'>
              <div>
                  <h3 className='light  uk-text-bold'>Mentorship</h3> 
                  <p className='light'>Our network of seasoned entrepreneurs and industry leaders are committed to guiding and mentoring startup founders.  </p> 
                    </div>
              </div>

              <div className='uk-width-1-4@s'>
              <div>
                  <h3 className='light   uk-text-bold'>Access to Markets</h3> 
                  <p className='light'>We facilitate connections with key stakeholders, potential customers, and distribution channels to help startups reach underserved markets at scale </p> 
                    </div>
              </div>

              <div className='uk-width-1-4@s'>
              <div>
                  <h3 className='light    uk-text-bold'>Collaborative Community</h3> 
                  <p className='light'>We foster a collaborative and supportive community where founders can connect, learn from each other, and share best practices </p> 
                    </div>
              </div>
          </div>

          </div>

    </div>
  )
}

export default Fourth_pane