import React from 'react'
import Layout from '../Layout/Layout'

const Our_believe = () => {
  return (
    <Layout>
        <div className='uk-container uk-margin-xlarge-top'>
            <h2 className='light uk-text-left'>
            Moolu Venture Movement is driven by a belief 
            in the transformative power of entrepreneurship 
            and seeks to create an ecosystem that empowers 
            and supports startups in their journey to make
            a positive difference. 
            </h2>



            <div className='uk-grid uk-margin-large-top' data-uk-grid>
                     <div className='uk-width-1-2@s'>
                     <p  className='light'>Through its resources, networks, and values-driven approach,
                 the movement aims to contribute to a thriving startup ecosystem
                 and foster innovation for the betterment of society.</p>
                     </div>

                     <div className='uk-width-1-2@s'>
                         <p className='light'>
                         The Moolu Venture Lab is a forward-thinking organization that believes in the 
                         power of entrepreneurship and innovation to drive positive change in society. 
                         </p>
                      </div>
            </div>

            <div className='uk-grid'>
                  <div className='uk-width-2-3@s'>
                        <h4 className='light'>The venture lab operates with a strong belief in the potential of startups
                             and their ability to create impactful solutions to pressing global challenges.</h4>
                  </div>


                  <div className='uk-width-1-3@s'>

                  </div>
            </div>

           
        </div>

        </Layout>
  )
}

export default Our_believe