import React from 'react'
import Layout from '../Layout/Layout'

const Programs = () => {
  return (
    <Layout>   
        <div className='uk-container uk-margin-xlarge-top'>
        <div>
            <h1 className='light'>Introducing the Moolu Venture Lab Accelerators Program</h1>

            <h4 className='light'>Welcome to the Moolu Venture Lab Accelerators Program, where ground-breaking startups and entrepreneurs
                 come together to supercharge their growth and accelerate their journey towards success. We believe in 
                 the power of innovation, collaboration, and mentorship, and we are committed to empowering the next
                  generation of game-changers.</h4>

        </div>



        <div className='uk-margin-large-top'>
            <h2 className='uk-text-bold light'>About Moolu Venture Lab programs</h2>
            <p className='light'>Moolu Venture Lab program is a global online innovation hub and
               startup ecosystem committed to fostering entrepreneurial talent
               and driving disruptive solutions across industries. With a strong
               network of experienced mentors, industry experts, and investors,
               Moolu Venture Lab provides startups with the resources, guidance,
                and connections they need to thrive.</p>
        </div>



        <div className='uk-margin-large-top'>
                <h2 className='uk-text-bold light'>Program Overview</h2>
                <h5 className='light'>The Moolu Venture Lab Accelerators Program is a highly selective
                    and intensive program designed to propel early-stage startups to
                    the next level. We understand that building a successful startup
                    requires more than just a great idea, which is why our program 
                    focuses on four key pillars</h5>

                <h5 className='uk-margin-medium-top light'><span className='uk-text-bold'>Mentorship:</span> Gain access to our network of seasoned entrepreneurs, industry experts,
                     and investors who will provide invaluable guidance, support, and insights tailored 
                     to your startup's specific needs. Benefit from their vast experience and tap into
                      their networks to accelerate your growth.</h5>

                <h5 className='uk-margin-medium-top light'><span className='uk-text-bold'>Resources:</span> Leverage our state-of-the-art learning management system infrastructure, 
                to create and refine your product or service. Access our extensive library of educational materials, workshops, and seminars
                 to enhance your skills and knowledge in key areas such as marketing, finance, operations, and more.
                 </h5>

                 <h5 className='uk-margin-medium-top light'><span className='uk-text-bold'>Collaboration:</span> Connect and collaborate with other like-minded startups
                  within our vibrant community. Share experiences, exchange ideas, and foster meaningful partnerships that can lead to new business opportunities, joint ventures, and shared learnings.

                 </h5>
                

        </div>



        <div className='uk-margin-large-top'>
             <h1 className='light uk-text-bold'>Featured Programs</h1>


             
             <div className='row uk-margin-medium-top'>
           <div className='col-sm-10'>
                
            <h4 className='light'><span className='uk-text-bold'>Intensive Bootcamp:</span> Kickstart the program with an immersive bootcamp designed to assess your startup's strengths, identify areas for improvement, and set clear growth goals.
Tailored Mentorship: Benefit from one-on-one mentoring sessions with industry experts who will provide personalized guidance to address your startup's unique challenges and opportunities.
 </h4>
             </div>

             <div className='col-sm-2'>
             </div>

             </div>


             <div className='row uk-margin-medium-top'>
           <div className='col-sm-10'>
                
            <h4 className='light'><span className='uk-text-bold'>1. Workshops and Masterclasses:</span> 
             Participate in interactive workshops and masterclasses led by renowned experts covering topics such
             as product development, marketing strategies, fundraising, scaling, and more
            </h4>

            <div className='' style={{paddingLeft:'30px',paddingRight:'50px'}}>
                        <h5 className='light'><span className='uk-text-bold'>Extensive Course Library :</span> Access a wide range of courses tailored specifically 
                            for tech founders. From product development and growth hacking to fundraising
                             and leadership, our curated collection covers all the essential topics you
                              need to master.
                        </h5>

                        <h5 className='light'><span className='uk-text-bold'>Investor Access :</span>
                        Get exclusive opportunities to pitch your startup to angel investors, venture capitalists, and other funding sources actively seeking 
                        innovative startups to invest in.
                        </h5>


                        <h5 className='light'><span className='uk-text-bold'>Demo Days and Showcases :</span> 
                        Present your startup to a wide audience of potential customers, partners, and investors
                         during our highly anticipated demo days and showcases.
                        </h5>


                        <h5 className='light'><span className='uk-text-bold'>Alumni Network :</span> Join our ever-growing community of successful Moolu Venture 
                        Lab alumni, granting you ongoing access to a supportive network of fellow entrepreneurs, industry leaders, and potential collaborators
                        </h5>

                        <p className='light uk-text-small' style={{padding:'30px'}}>To apply for the Moolu Venture Lab Accelerators Program, please join our newsletter and stay connected on our social media
                             platform to complete the online application form. Our selection process is rigorous, and we carefully evaluate each 
                             application based on factors such as team strength, market potential, uniqueness of the idea, and scalability.</p>
                  </div>




             </div>

             <div className='col-sm-2'>
             </div>
                 

             </div>





             <div className='row uk-margin-medium-top'>
           <div className='col-sm-10'>
                
            <h4 className='light'><span className='uk-text-bold'>2. Intensive Bootcamp:</span> Kickstart the program with an immersive bootcamp designed to assess your startup's strengths, identify areas for improvement, and set clear growth goals.
Tailored Mentorship: Benefit from one-on-one mentoring sessions with industry experts who will provide personalized guidance to address your startup's unique challenges and opportunities.
 </h4>
             </div>

             <div className='col-sm-2'>
             </div>

             </div>



             <div className='row uk-margin-medium-top'>
           <div className='col-sm-10'>
                
            <h4 className='light'><span className='uk-text-bold'>3.	Accelerators Programs for early stage founders and Growth founders:</span>
            Join us at Moolu Venture Lab Accelerators Program and unlock the full potential of your startup.        Let's embark on this transformative
             journey together and build the future of innovation!
 </h4>
             </div>

             <div className='col-sm-2'>
             </div>

             </div>


             <div className='row uk-margin-medium-top'>
           <div className='col-sm-10'>
                
            <h4 className='light'><span className='uk-text-bold'>4.Free Courses for Founders:</span>
            Whether you're just starting your entrepreneurial journey or looking to level up your skills, we've got you covered. Dive into a world of knowledge and discover the tools and insights you need to succeed in the ever-evolving tech landscape.
Access a range of free courses tailored specifically for tech founders. From product development and growth hacking to fundraising and leadership.

 </h4>
             </div>

             <div className='col-sm-2'>
             </div>

             </div>












        </div>









    </div>

    </Layout>

  )
}

export default Programs