import React from 'react'
import '../Home.css'
import sample from '../../../videos/default.mp4'

const Wave_pane = () => {
  return (
    <div className=''>
      <div className='main'>
          <div className='' >
              < video src={sample}  autoPlay loop muted />
                <div className='content'>
                    <div className='header_Text_Cont'>
                <h1 >Welcome to the<span style={{color:"#F7981D"}}> Moolu <br/>Venture </span> Movement!</h1>
               <h4>We believe in Venture for impact; Innovation is here!</h4>
               </div>

                </div>

                <div className='uk-container'>
             <div className=''>
                
             
                </div>

            
        </div>
             
         </div>

     </div>

   


   
   
    </div>
  )
}

export default Wave_pane