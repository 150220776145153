import React from 'react'

const Fifth_pane = () => {
  return (
    <div className='uk-container uk-margin-large-top'>
        <div>
            <div className='uk-grid' data-uk-grid>
                <div className='uk-width-2-5@s'>

                <h2 className='light uk-text-bold'>Our Programs</h2>
                  <div>
                    <ul className='program_menu'>
                   <li> Intensive Bootcamp </li>
                   <li> Workshops and Masterclasses </li>
                   <li>  Accelerators Programs </li>
                   <li>  Free Courses </li>

                    </ul>
                  </div>

                  
                   
                </div>

                <div className='uk-width-3-5@s'>

                <h2  className='light uk-text-bold'>Join the Moolu Venture Lab Movement</h2>
                <p className='light'>If you are a visionary founder working on an innovative tech startup that aligns with our mission, 
                    we invite you to join the Moolu Venture Lab Movement. </p>

               </div>
            </div>
    </div>
    </div>
  )
}

export default Fifth_pane