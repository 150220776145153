import React from 'react'
import '../styles.css'

const First_pane = () => {
  return (
    <div>
        <div className='uk-container'>
             <h1 className='light uk-margin-xlarge-top'>The Moolu Venture Movement is a dynamic ecosystem that
         brings together a diverse community of entrepreneurs, 
         mentors, investors, and experts who are passionate about 
         creating positive change.</h1>


          <div className='uk-grid uk-margin-large-top' data-uk-grid>
                 <div className='uk-width-1-2@s'>
                 <p className='light'>  We believe in Venture for impact
          and hence we provide a comprehensive range of resources, 
          support, and strategic guidance to help startups thrive and 
          scale their impact.</p>
                 </div>

                 <div className='uk-width-1-2@s'>
                 <p className='light'> By leveraging our network and expertise,
           we empower founders to overcome challenges, accelerate growth,
            and achieve their vision.</p>
                  </div>
          </div>
      

            </div>
   

      

    </div>
  )
}

export default First_pane