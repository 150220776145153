import React from 'react'
import emerald from '../../../images/emerald.png'
import etech from '../../../images/etech.png'


const Third_pane = () => {
  return (
    <div>
        <div className='uk-margin-large-top uk-container'>
            <h1 className='light'>Events</h1>

            <div className='uk-grid' data-uk-grid>
                   <div className='uk-width-1-2@s'>
                       <div className='border'>
                        <img src={emerald} />
                       <h3 className='uk-text-bold light'>Emerald Challenge</h3>
                       <p className='light'>Moolu Venture Lab is thrilled to present "Emerald 
                       PitchFest 2023 Challenge," a prestigious pitch event designed to celebrate
                        and propel the next generation of growth startups in Nigeria.  </p>
                        </div>
                   </div>

                   <div className='uk-width-1-2@s'>
                       <div className='border'>
                        <img src={etech} className='etech_img'/>
                       <h3 className='uk-text-bold light'>Emerging Tech Summit</h3>
                       <p className='light'>Moolu Venture Capital LP proudly presents the E-Tech Summit,
                        an immersive event designed to ignite innovation, foster collaboration, and empower
                         the future of technology. </p>
                        </div>
                   </div>
            </div>
        </div>
    </div>
  )
}

export default Third_pane