import React from 'react'

const Second_pane = () => {
  return (
    <div className='uk-margin-xlarge-top'>
        <div className='uk-container'>
            <h4 className='orange'>WHAT WE DO</h4>
            <div className='row'>
              <div className='col-sm-9  second_pane_div'>
                
         <h1 className='light'>We believe in Venture for impact and hence we provide a
          comprehensive range of resources, support, and strategic guidance to help 
          startups thrive and scale their impact.  </h1>
              </div>
              <div className='col-sm-3'>

                </div>
            </div>

          
            <div className='row uk-margin-large-top'>
                <h2 className='light uk-text-bold'>Our Focus Areas</h2>
                 <div className='col-sm-6 uk-margin-small-top'>
                        <div className='border-orange'>
                             <div className='row'>
                                  <div className='col-sm-3'>
                                    <p></p>
                                  </div>

                                  <div className='col-sm-9'>
                                     <h4 className='light uk-text-bold'>Increasing Access</h4>
                                     <p className='light'>We believe that everyone should have equal access to essential
                                         services. We actively seek out startups that are working on
                                          innovative solutions to bridge the access gap in sectors such
                                           as healthcare, education, finance, energy, and more</p>
                                    </div>
                             </div>
                        </div>
                 </div>

                 <div className='col-sm-6'>
                 <div className='border-orange'>
                             <div className='row'>
                                  <div className='col-sm-3'>
                                    <p></p>
                                  </div>

                                  <div className='col-sm-9'>
                                     <h4 className='light uk-text-bold'>Improving Quality</h4>
                                     <p className='light'>We recognize the importance of enhancing the quality 
                                     of services available to the underserved. We support startups that
                                      are dedicated to developing cutting-edge technologies and scalable
                                       solutions that raise the standard of care. </p>
                                    </div>
                             </div>
                        </div>
                 </div>
            </div>






            <div className='row uk-margin-medium-top'>
                 <div className='col-sm-6'>
                        <div className='border-orange'>
                             <div className='row'>
                                  <div className='col-sm-3'>
                                    <p>hello</p>
                                  </div>

                                  <div className='col-sm-9'>
                                     <h4 className='light uk-text-bold'>Reducing Costs</h4>
                                     <p className='light'>We understand the significance of affordability in
                                      ensuring widespread access to vital services. Startups that focus on
                                       reducing costs while maintaining high-quality standards have the
                                        potential to create transformative change</p>
                                    </div>
                             </div>
                        </div>
                 </div>

                 <div className='col-sm-6'>
         
                 </div>
            </div>






        </div>
    </div>
  )
}

export default Second_pane