import React from 'react'
import Layout from '../Layout/Layout'

const Events = () => {
  return (
    <Layout>
          <div className='uk-container uk-margin-xlarge-top'>
            <h3 className='light'>Moolu Venture Lab organizes a diverse range of events designed to
                 foster innovation, collaboration, and entrepreneurship. These events
                  serve as platforms for startups, entrepreneurs, and industry experts
                   to connect, share knowledge, and explore opportunities for growth.</h3>

    

          </div>


          <div className='uk-container uk-margin-large-top'>
          <h2 className='uk-text-bold light'>The events organized by Moolu Venture Lab often include:</h2>

              <div>
              <h4 className='light'><span className='uk-text-bold'>Startup Pitch Competitions:</span> These events provide early-stage startups with an opportunity to 
              showcase their ideas and products to a panel of investors, mentors, and industry professionals. 
              Startups present their business plans and innovations, competing for funding and support to further
               develop their ventures.
               </h4>
              </div>


              
              <div className='uk-margin-medium-top'>
              <h4 className='light'><span className='uk-text-bold'>	Networking Sessions (Meet and Greet) :</span>
               Moolu Venture Lab hosts networking events where 
              entrepreneurs, investors, and industry experts come together to connect and forge meaningful
               relationships. These sessions enable startups to expand their networks, find potential
                collaborators or investors, and gain valuable insights from experienced professionals.
               </h4>
              </div>



              <div className='uk-margin-medium-top'>
              <h4 className='light'><span className='uk-text-bold'>	Workshops and Seminars :</span>
              Moolu Venture Lab organizes workshops and seminars that cover various aspects of 
              entrepreneurship, business development, and innovation. These events provide startups
              with valuable knowledge and practical skills, helping them navigate challenges and
              accelerate their growth
               </h4>
              </div>


              <div className='uk-margin-medium-top'>
              <h4 className='light'><span className='uk-text-bold'>	Panel Discussions and Expert Talks: </span>
              Moolu Venture Lab invites industry experts, successful entrepreneurs, and thought leaders to share 
              their insights and experiences through panel discussions and expert talks. These sessions offer 
              valuable perspectives on emerging trends, industry best practices, and strategies for startup success
               </h4>
              </div>



              <div className='uk-margin-medium-top'>
              <h4 className='light'><span className='uk-text-bold'>	Innovation Challenges: </span>
              Moolu Venture Lab hosts innovation challenges that encourage startups to develop solutions for
               specific social or environmental problems. These challenges provide a platform for startups to
                showcase their creativity and problem-solving abilities while addressing pressing global issues
               </h4>
              </div>


              <div class="uk-margin-medium-top" >
                <h5 className='light'>Through these events, Moolu Venture Lab aims to create an inclusive and collaborative environment 
                    that nurtures the growth of startups, facilitates knowledge exchange, and promotes innovation for 
                    the betterment of society.</h5>
              </div>


              

          </div>
    </Layout>
  )
}

export default Events