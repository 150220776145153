import React from 'react'
import Layout from '../Layout/Layout'
import './i_am.css'

const I_am_statement = () => {
  return (
   <Layout>

      <div className='uk-margin-xlarge-top'>
        <div className='uk-container'>      
   <ul className='i_am'>
<li>I am a catalyst for positive change in my local community.  </li>
<li>I am committed to creating a thriving and vibrant local economy.</li>
<li>I am dedicated to supporting local businesses and entrepreneurs.</li>
<li>I am an active participant in building a strong sense of community.</li>
<li>I am a champion of sustainable and environmentally friendly practices.</li>
<li>I am empowering others to realize their dreams and reach their full potential.</li>
<li>I am fostering collaboration and cooperation among local organizations.</li>
<li>I am fostering innovation and creativity in my local area.</li>
<li>I am a driving force for social impact and philanthropy in my community.</li>
<li>I am creating opportunities for local talent to shine and flourish.</li>
<li>I am igniting a spirit of entrepreneurship and self-reliance.</li>
<li>I am building bridges between different sectors and industries in my community.</li>
<li>I am promoting diversity, inclusivity, and equality in all aspects of local business.</li>
<li>I am inspiring others to believe in the power of local initiatives.</li>
<li>I am a source of inspiration and motivation for others to get involved in the local venture movement.</li>

<li>I am a catalyst for positive change in the world.</li>
<li>I am part of a global venture movement that is transforming lives.</li>
<li>I am committed to creating a better future for generations to come.</li>
<li>I am empowered to make a difference through my entrepreneurial endeavors.</li>
<li>I am surrounded by a supportive community of like-minded individuals.</li>
<li>I am passionate about creating innovative solutions to global challenges.</li>
<li>I am continuously learning and growing as I embark on this global venture.</li>
<li>I am resilient and adaptable in the face of obstacles and setbacks.</li>
<li>I am making a positive impact on local communities around the world.</li>
<li>I am part of a movement that is redefining the boundaries of what is possible.</li>
<li>I am dedicated to fostering collaboration and cooperation among global entrepreneurs.</li>
<li>I am an agent of positive change, bringing innovation and progress to the world.</li>
<li>I am constantly seeking new opportunities to expand my global reach.</li>
<li>I am committed to sustainable and ethical business practices.</li>
<li>I am inspired by the potential of collective action to solve global problems.</li>
<li>I am a visionary, driven by a sense of purpose and a desire to make a difference.</li>
<li>I am an advocate for diversity and inclusion, valuing the perspectives of all individuals.</li>
<li>I am driven by a deep belief in the power of entrepreneurship to create a better world.</li>
<li>I am dedicated to creating lasting social and environmental impact through my ventures.</li>
<li>I am part of a movement that is reshaping industries and creating new possibilities.</li>

 </ul>
      </div>
      </div>


    </Layout>
  )
}

export default I_am_statement