import React from 'react'
import Layout from '../Layout/Layout'

const Creed = () => {
  return (
    <Layout>
        <div className='uk-container uk-margin-xlarge-top'>
            <div className='uk-width-1-1'>
                <h1 className='light'>Creed</h1>
                <h2 className='light'>I pledge to uphold the principles and values of the
                     Moolu Venture  Movement (MVM) , and in doing so, I 
                     solemnly swear to abide by the following creed:
                </h2> 
            </div>


            <div className='uk-margin-large-top'> 
            <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Purpose:</span> I believe in the transformative 
            power of entrepreneurship and innovation.  I commit to fostering and supporting ventures that have 
            the potential to create  positive change in the world. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>





             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Collaboration</span> I recognize that together we can achieve more.
             I will actively seek out opportunities for collaboration, sharing knowledge, and nurturing a community of entrepreneurs, 
             mentors, and investors. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>




             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Empowerment:</span> I will strive to empower entrepreneurs by
             providing them with the resources, mentorship, and guidance they need to succeed. I will encourage risk-taking, 
             resilience, and a growth mindset. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>








             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Inclusivity:</span> I embrace diversity in all its forms.
             I will work towards creating an inclusive and equitable environment where individuals from all backgrounds,
              genders, races, and ethnicities feel welcome and valued. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>


             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Ethical Conduct:</span> I will adhere to the highest standards of 
            integrity, honesty, and transparency. I will act ethically in all my interactions and decisions, fostering a culture of
             trust and accountability </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>



             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Continuous Learning:</span> I am committed to lifelong learning and personal
             growth. I will continuously seek to expand my knowledge, stay abreast of emerging trends, and adapt to the evolving needs of 
             entrepreneurs and the startup ecosystem. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>



             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Global Perspective:</span> I acknowledge that innovation knows no boundaries. 
            I will embrace a global mindset, actively seeking opportunities to connect with entrepreneurs from around the world and contribute 
            to the growth of a global entrepreneurial community. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>




             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Impact-driven:</span> I will prioritize ventures 
            that have a positive social, environmental, or economic impact. I will encourage entrepreneurs to build 
            sustainable and responsible businesses that contribute to the betterment of society </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>





             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Resilience:</span>I recognize that entrepreneurship
             is a challenging journey. I will support entrepreneurs in overcoming obstacles, celebrating their
              successes, and fostering a culture of resilience, perseverance, and adaptability </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>



             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Pay It Forward:</span>I will commit to giving back to the 
            ecosystem that supports me. I will mentor aspiring entrepreneurs, share my knowledge and experiences, and 
            contribute to the growth and success of future generations of innovators. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>


             <div className='row uk-margin-medium-top'>
           <div className='col-sm-9'>
                
            <h4 className='light'><span className='uk-text-bold'>Pay It Forward:</span>I will commit to giving back to the 
            ecosystem that supports me. I will mentor aspiring entrepreneurs, share my knowledge and experiences, and 
            contribute to the growth and success of future generations of innovators. </h4>
             </div>

             <div className='col-sm-3'>
             </div>

             </div>

             <h4 className='uk-text-bold light uk-margin-large-top'>With this creed as my guiding light, I pledge to be an active member of the Moolu Venture Movement, 
                working tirelessly to nurture and accelerate ventures that have the potential to shape a brighter
                 future for all</h4>













                 

            </div>

           

        </div>

    </Layout>
  )
}

export default Creed